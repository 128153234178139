import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { setCulture } from '@syncfusion/ej2-base';
import { TranslocoService } from '@jsverse/transloco';
import { filter, switchMap, tap } from 'rxjs';
import { ApplicationStateService } from './shared/services/application-state.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';
import { ToastService } from './shared/services/toast.service';

@Component({
  selector: 'goatsports-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private readonly translocoService = inject(TranslocoService);
  private readonly applicationStateService = inject(ApplicationStateService);
  private readonly router = inject(Router);
  private readonly toastService = inject(ToastService);

  routeChanges = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      tap(() => {
        this.toastService.hideAllToasts();
      }),
    ),
  );

  languageChanges = toSignal(
    this.translocoService.langChanges$.pipe(
      switchMap((language) =>
        this.translocoService.load(language).pipe(
          tap(() => {
            this.applicationStateService.languageChanged = language;
            setCulture(language);
          }),
        ),
      ),
    ),
  );
}
