import { ThreeViewFieldsDataSource } from '../models/three-view-fields-data-source';
import { RouteConstants } from '../constants/route.constant';
import { RoleType } from '@goatsports/core/data-access';

type RolePermissions = Partial<{
  [key in RoleType]: ThreeViewFieldsDataSource[];
}>;

export const ROLE_PERMISSIONS: RolePermissions = {
  [RoleType.SuperAdmin]: [
    {
      id: `/${RouteConstants.DASHBOARD_ROUTE}`,
      text: 'Dashboard',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.HABITS_ROUTE}`,
      text: 'Habits',
      iconCss: 'e-icons',
      hasChildren: true,
    },

    {
      id: `/${RouteConstants.EXERCISES_ROUTE}`,
      text: 'Exercises',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.WORKOUTS_ROUTE}`,
      text: 'Workouts',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.WORKOUT_PROGRAMS_ROUTE}`,
      text: 'Workout Programs',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.DAILY_WORKOUTS_ROUTE}`,
      text: 'Daily Workouts',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.INGREDIENTS_ROUTE}`,
      text: 'Ingredients',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.MEALS_ROUTE}`,
      text: 'Meals',
      iconCss: 'e-icons',
    },

    {
      id: `/${RouteConstants.MEAL_PLAN_ROUTE}`,
      text: 'Meal Plan',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.DAILY_MEALS_ROUTE}`,
      text: 'Daily Meals',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.QUIZ_ROUTE}`,
      text: 'Quiz',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.REFLECTIONS_ROUTE}`,
      text: 'Reflections',
      iconCss: 'e-icons',
      hasChildren: true,
    },

    {
      id: `/${RouteConstants.ORGANIZATIONS_ROUTE}`,
      text: 'Organizations',
      iconCss: 'e-icons',
      hasChildren: true,
    },

    {
      id: `/${RouteConstants.BUNDLES_ROUTE}`,
      text: 'Bundles',
      iconCss: 'e-icons',
      hasChildren: true,
    },

    {
      id: `/${RouteConstants.SETTINGS_ROUTE}`,
      text: 'Settings',
      iconCss: 'e-icons',
    },
  ],
  [RoleType.OrganizationAdmin]: [
    {
      id: `/${RouteConstants.DASHBOARD_ROUTE}`,
      text: 'Dashboard',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.CLIENTS_ROUTE}`,
      text: 'Clients',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.GROUPS_ROUTE}`,
      text: 'Groups',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.HABITS_ROUTE}`,
      text: 'Habits',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.EXERCISES_ROUTE}`,
      text: 'Exercises',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.WORKOUTS_ROUTE}`,
      text: 'Workouts',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.WORKOUT_PROGRAMS_ROUTE}`,
      text: 'Workout Programs',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.INGREDIENTS_ROUTE}`,
      text: 'Ingredients',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.MEALS_ROUTE}`,
      text: 'Meals',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.MEAL_PLAN_ROUTE}`,
      text: 'Meal Plan',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.QUIZ_ROUTE}`,
      text: 'Quiz',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.REFLECTIONS_ROUTE}`,
      text: 'Reflections',
      iconCss: 'e-icons',
      hasChildren: true,
    },

    {
      id: `/${RouteConstants.COACHES_ROUTE}`,
      text: 'Coaches',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.TEAMS_ROUTE}`,
      text: 'Teams',
      iconCss: 'e-icons',
    },
  ],
  [RoleType.Coach]: [
    {
      id: `/${RouteConstants.DASHBOARD_ROUTE}`,
      text: 'Dashboard',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.CLIENTS_ROUTE}`,
      text: 'Clients',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.GROUPS_ROUTE}`,
      text: 'Groups',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.HABITS_ROUTE}`,
      text: 'Habits',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.EXERCISES_ROUTE}`,
      text: 'Exercises',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.WORKOUTS_ROUTE}`,
      text: 'Workouts',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.WORKOUT_PROGRAMS_ROUTE}`,
      text: 'Workout Programs',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.INGREDIENTS_ROUTE}`,
      text: 'Ingredients',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.MEALS_ROUTE}`,
      text: 'Meals',
      iconCss: 'e-icons',
    },
    {
      id: `/${RouteConstants.MEAL_PLAN_ROUTE}`,
      text: 'Meal Plan',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.QUIZ_ROUTE}`,
      text: 'Quiz',
      iconCss: 'e-icons',
      hasChildren: true,
    },
    {
      id: `/${RouteConstants.REFLECTIONS_ROUTE}`,
      text: 'Reflections',
      iconCss: 'e-icons',
      hasChildren: true,
    },

    {
      id: `/${RouteConstants.COACHES_ROUTE}`,
      text: 'Coaches',
      iconCss: 'e-icons',
    },
  ],
  [RoleType.Translator]: [
    {
      id: `/${RouteConstants.CONFIGURE_ROUTE}`,
      text: 'Settings',
      iconCss: 'e-icons',
    },
  ],
};
